.asside h6{
   color: #f3f3f3;
    font-family: "Inter", sans-serif;  

}
.asside{
    display: flex;
    height: 10vh;
    background-color: #202120;
    flex-direction: row;
    align-items: center;
    text-align: center;
    justify-content: space-around;
}
.all{
    overflow-y: hidden;
}