@media only screen and (max-width: 768px) {


  .navInclusive{
      height: 10vh;
      background-color: #F65909;
      width: 100vw;
      margin-bottom: 5vh;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding:0vw 5vw 0vw 5vw ;
  }
  .navInclusive img{
      height: 5vh;
  }
  .navInclusive .logo{
    height: 8vh;
  }
  .indic{
      background-color: transparent;
      background-image: radial-gradient(#F65909 1px, #fff 1px);
      background-size: 30px 30px;
      display: flex;
      align-items: center;
      flex-direction: column;
  } 
   .contIndic{
      margin-right: 8vw;
  
   }
   .indic ul{
       columns: 1;
       justify-content: space-around;
       list-style: none;
   }
   .indic li{
      margin-left: 8vw;
      margin-bottom: 8vh;
   }
   
   
   
   
   
   
   ul li .card {
      position: relative;
      width: 300px;
      height: 200px;
      background-color: #f2f2f2;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      perspective: 1000px;
      box-shadow: 0 0 0 5px #ffffff80;
      transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }
    
    ul li .card img {
      width: 100%;
      fill: #333;
      transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }
    *{
      scroll-behavior: smooth
    }
    ul li .card:hover {
      transform: scale(1.05);
      box-shadow: 0 8px 16px rgba(255, 255, 255, 0.2);
    }
    img{
      object-fit: cover;
    }
    ul li .card__content {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 20px;
      box-sizing: border-box;
      background-color: #f2f2f2;
      transform: rotateX(-90deg);
      transform-origin: bottom;
      transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }
    
    ul li .card:hover  .card__content {
      transform: rotateX(0deg);
    }
    
    ul li .card__title {
      margin: 0;
      font-size: 24px;
      color: #333;
      font-weight: 700;
    }
    
    ul li .card:hover img {
      scale: 0;
    }
    
    ul li .card__description {
      margin: 10px 0 0;
      font-size: 14px;
      color: #777;
      line-height: 1.4;
    }
  }


@media only screen and (min-width: 769px) {


.navInclusive{
    height: 10vh;
    background-color: #F65909;
    width: 100vw;
    margin-bottom: 5vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding:0vw 5vw 0vw 5vw ;
}
.navInclusive img{
    height: 5vh;
}
.navInclusive .logo{
  height: 8vh;
}
.indic{
    background-color: transparent;
    background-image: radial-gradient(#F65909 1px, #fff 1px);
    background-size: 30px 30px;
    display: flex;
    align-items: center;
    flex-direction: column;
} 
 .contIndic{
    margin-right: 8vw;

 }
 .indic ul{
     columns: 2;
     justify-content: space-around;
     list-style: none;
 }
 .indic li{
    margin-left: 8vw;
    margin-bottom: 8vh;
 }
 
 
 
 
 
 
 ul li .card {
    position: relative;
    width: 300px;
    height: 200px;
    background-color: #f2f2f2;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    perspective: 1000px;
    box-shadow: 0 0 0 5px #ffffff80;
    transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  
  ul li .card img {
    width: 100%;
    fill: #333;
    transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  *{
    scroll-behavior: smooth
  }
  ul li .card:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(255, 255, 255, 0.2);
  }
  img{
    object-fit: cover;
  }
  ul li .card__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 20px;
    box-sizing: border-box;
    background-color: #f2f2f2;
    transform: rotateX(-90deg);
    transform-origin: bottom;
    transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  
  ul li .card:hover  .card__content {
    transform: rotateX(0deg);
  }
  
  ul li .card__title {
    margin: 0;
    font-size: 24px;
    color: #333;
    font-weight: 700;
  }
  
  ul li .card:hover img {
    scale: 0;
  }
  
  ul li .card__description {
    margin: 10px 0 0;
    font-size: 14px;
    color: #777;
    line-height: 1.4;
  }
}