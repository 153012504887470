@media only screen and (max-width: 768px) {

.Contato {
    background-color: transparent;
    background-image: radial-gradient(#F65909 1px, #fff 1px);
    background-size: 30px 30px;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    overflow: hidden;
  }
  .Contato h1{
    width: 15vh;
    cursor: pointer;
  }
  .element {
    transform: rotate(25deg) translate(-39.2vw, 51px) scale(1.1, 1.5) skew(-44deg, 7deg);
    background-color: rgba(246, 94, 6, 1);
    border-radius: 10px;
    margin-top: 50vh;
    height: 70vh;
    width: 60vw;
  }
.imageCntt img{
  transform: scaleX(-1);
  height: 50vh;
}
.containerContato{
  display: flex;
  flex-direction: column;
}
.inputs{
  font-family: "Inter", sans-serif;  

  width: 60vw;
  background-image: url(../../assets/svgs/blob.svg);
  background-repeat: no-repeat;
  background-position-x: center;
  background-size: 85%;
  display: none;
  flex-direction: column;
  align-items: center;
}
  /* inputs/*/
  .inputs .contInp{
    padding-top: 22vh;
    margin-right: 3vw;
    color: #fff;
    align-items: center;
    justify-content: center;
    width: 30vw;
    height: 65vh;
    margin-top: 3vh;
  }
  .inputGroup {
    font-family: 'Segoe UI', sans-serif;
    margin: 1em 0 1em 0;
    max-width: 190px;
    position: relative;
  }
  .inpas{
    display: flex ;
    flex-direction: column;
    align-items: center;
  }
  .inputGroup input {
    font-size: 100%;
    padding: 0.8em;
    outline: none;
    border: 2px solid rgb(200, 200, 200);
    background-color: transparent;
    border-radius: 20px;
    width: 100%;
  }
  
  .inputGroup label {
    font-size: 100%;
    position: absolute;
    left: 0;
    padding: 0.8em;
    margin-left: 0.5em;
    pointer-events: none;
    transition: all 0.3s ease;
  }
  
  .inputGroup input:focus,
  .inputGroup input:valid {
    border-color: rgb(150, 150, 200);
  }
  
  .inputGroup input:focus ~ label,
  .inputGroup input:valid ~ label {
    transform: translateY(-50%) scale(0.9);
    margin: 0;
    margin-left: 1.3em;
    padding: 0.4em;
    background-color: #F65E06;
  }



  .inpas .animated-button {
    position: relative;
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 16px 36px;
    border: 4px solid;
    border-color: transparent;
    font-size: 16px;
    background-color: inherit;
    border-radius: 100px;
    font-weight: 600;
    color: #fff;
    box-shadow: 0 0 0 2px #fff;
    cursor: pointer;
    overflow: hidden;
    transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
  }
  
  .inpas .animated-button svg {
    position: absolute;
    width: 24px;
    fill: #fff;
    z-index: 9;
    transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
  }
  
  .inpas .animated-button .arr-1 {
    right: 16px;
  }
  
  .inpas .animated-button .arr-2 {
    left: -25%;
  }
  
  .inpas .animated-button .circle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 20px;
    background-color: #fff;
    border-radius: 50%;
    opacity: 0;
    transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
  }
  
  .inpas .animated-button .text {
    position: relative;
    z-index: 1;
    transform: translateX(-12px);
    transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
  }
  
  .inpas .animated-button:hover {
    box-shadow: 0 0 0 12px transparent;
    color: #000;
    border-radius: 12px;
  }
  
  .inpas .animated-button:hover .arr-1 {
    right: -25%;
  }
  
  .inpas .animated-button:hover .arr-2 {
    left: 16px;
  }
  
  .inpas .animated-button:hover .text {
    transform: translateX(12px);
  }
  
  .inpas .animated-button:hover svg {
    fill: #000;
  }
  
  .inpas .animated-button:active {
    scale: 0.95;
    box-shadow: 0 0 0 4px greenyellow;
  }
  
  .inpas .animated-button:hover .circle {
    width: 220px;
    height: 220px;
    opacity: 1;
  }
  



  .cards {
    margin-top: 3vh;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .cards .red {
    background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #ff53d4 60%, #62c2fe 90%);
    ;
  }
  
  .cards .blue {
    background-color: #3b82f6;
    display: none;
  }
  
  .cards .green {
    background-color: #22c55e;
  }
  
  .cards .card {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    height: 100px;
    width: 250px;
    border-radius: 10px;
    color: white;
    cursor: pointer;
    transition: 400ms;
  }
  
  .cards .card p.tip {
    font-size: 1em;
    font-weight: 700;
  }
  
  .cards .card p.second-text {
    font-size: .7em;
  }
  
  .cards .card:hover {
    transform: scale(1.1, 1.1);
  }
  
  .cards:hover > .card:not(:hover) {
    filter: blur(10px);
    transform: scale(0.9, 0.9);
  }
}
@media only screen and (min-width: 769px) {

  .Contato {
      background-color: transparent;
      background-image: radial-gradient(#F65909 1px, #fff 1px);
      background-size: 30px 30px;
      width: 100vw;
      height: 100vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      justify-content: center;
      overflow: hidden;
    }
    .Contato h1{
      width: 15vh;
      cursor: pointer;
    }
    .element {
      transform: rotate(25deg) translate(-39.2vw, 51px) scale(1.1, 1.5) skew(-44deg, 7deg);
      background-color: rgba(246, 94, 6, 1);
      border-radius: 10px;
      margin-top: 50vh;
      height: 70vh;
      width: 60vw;
    }
  .imageCntt img{
    transform: scaleX(-1);
    height: 70vh;
  }
  .containerContato{
    display: flex;
    flex-direction: row;
  }
  .inputs{
    font-family: "Inter", sans-serif;  
  
    width: 60vw;
    background-image: url(../../assets/svgs/blob.svg);
    background-repeat: no-repeat;
    background-position-x: center;
    background-size: 85%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
    /* inputs/*/
    .inputs .contInp{
      padding-top: 22vh;
      margin-right: 3vw;
      color: #fff;
      align-items: center;
      justify-content: center;
      width: 30vw;
      height: 65vh;
      margin-top: 3vh;
    }
    .inputGroup {
      font-family: 'Segoe UI', sans-serif;
      margin: 1em 0 1em 0;
      max-width: 190px;
      position: relative;
    }
    .inpas{
      display: flex ;
      flex-direction: column;
      align-items: center;
    }
    .inputGroup input {
      font-size: 100%;
      padding: 0.8em;
      outline: none;
      border: 2px solid rgb(200, 200, 200);
      background-color: transparent;
      border-radius: 20px;
      width: 100%;
    }
    
    .inputGroup label {
      font-size: 100%;
      position: absolute;
      left: 0;
      padding: 0.8em;
      margin-left: 0.5em;
      pointer-events: none;
      transition: all 0.3s ease;
    }
    
    .inputGroup input:focus,
    .inputGroup input:valid {
      border-color: rgb(150, 150, 200);
    }
    
    .inputGroup input:focus ~ label,
    .inputGroup input:valid ~ label {
      transform: translateY(-50%) scale(0.9);
      margin: 0;
      margin-left: 1.3em;
      padding: 0.4em;
      background-color: #F65E06;
    }
  
  
  
    .inpas .animated-button {
      position: relative;
      display: flex;
      align-items: center;
      gap: 4px;
      padding: 16px 36px;
      border: 4px solid;
      border-color: transparent;
      font-size: 16px;
      background-color: inherit;
      border-radius: 100px;
      font-weight: 600;
      color: #fff;
      box-shadow: 0 0 0 2px #fff;
      cursor: pointer;
      overflow: hidden;
      transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
    }
    
    .inpas .animated-button svg {
      position: absolute;
      width: 24px;
      fill: #fff;
      z-index: 9;
      transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
    }
    
    .inpas .animated-button .arr-1 {
      right: 16px;
    }
    
    .inpas .animated-button .arr-2 {
      left: -25%;
    }
    
    .inpas .animated-button .circle {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 20px;
      height: 20px;
      background-color: #fff;
      border-radius: 50%;
      opacity: 0;
      transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
    }
    
    .inpas .animated-button .text {
      position: relative;
      z-index: 1;
      transform: translateX(-12px);
      transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
    }
    
    .inpas .animated-button:hover {
      box-shadow: 0 0 0 12px transparent;
      color: #000;
      border-radius: 12px;
    }
    
    .inpas .animated-button:hover .arr-1 {
      right: -25%;
    }
    
    .inpas .animated-button:hover .arr-2 {
      left: 16px;
    }
    
    .inpas .animated-button:hover .text {
      transform: translateX(12px);
    }
    
    .inpas .animated-button:hover svg {
      fill: #000;
    }
    
    .inpas .animated-button:active {
      scale: 0.95;
      box-shadow: 0 0 0 4px greenyellow;
    }
    
    .inpas .animated-button:hover .circle {
      width: 220px;
      height: 220px;
      opacity: 1;
    }
    
  
  
  
    .cards {
      margin-top: 3vh;
      display: flex;
      flex-direction: row;
      gap: 15px;
    }
    
    .cards .red {
      background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #ff53d4 60%, #62c2fe 90%);
      ;
    }
    
    .cards .blue {
      background-color: #3b82f6;
      display: none;
    }
    
    .cards .green {
      background-color: #22c55e;
    }
    
    .cards .card {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      height: 100px;
      width: 250px;
      border-radius: 10px;
      color: white;
      cursor: pointer;
      transition: 400ms;
    }
    
    .cards .card p.tip {
      font-size: 1em;
      font-weight: 700;
    }
    
    .cards .card p.second-text {
      font-size: .7em;
    }
    
    .cards .card:hover {
      transform: scale(1.1, 1.1);
    }
    
    .cards:hover > .card:not(:hover) {
      filter: blur(10px);
      transform: scale(0.9, 0.9);
    }
  }