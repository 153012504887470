h1{
  font-family: "Inter", sans-serif;   
}

@media only screen and (min-width: 769px) {

.sobre h1{
  color: rgb(34, 33, 33);
  padding: 0;
  width: 10vw;
  border-radius: 10px;
  text-align: center;
  margin-top: 2vh;
}
.cardSobre{

  height: 85vh;
  width: 40vw;
  margin-left: 5vw;
  margin-right: 5vw;
  text-align: center;
  font-family: "Inter", sans-serif;   

}
.card{
font-family: "Inter", sans-serif;   

}
.cardSobre h3{
  margin-top: 3vh;
  font-family: "Inter", sans-serif;   
}
.cardSobre img{
  width: 35vw;
  height: 30vh;
}
.sobre{
  align-items: center;
  display: flex;
  height: 100vh;
  flex-direction: column;
}
.containerSobre{
  display: flex;
  flex-direction: row;
}


.mvv{
  display: flex;
  width: 45vw;
  align-items: center;
  flex-direction: column;
}
.mCont{
  margin-top: 11vh;
  width: 20vw;
  height: 30vh;
}
.vCont{
  width: 40vw;
  height: 35vh;
  display: flex;
  flex-direction: row;
}

.card-container {
  width:20vw;
  height: 45vh;
  position: relative;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.card {
  width: 100%;
  height: 100%;
  border-radius: inherit;
}

.card .front-content {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 10)
}

.card .front-content p {
  font-size: 2rem;
  font-weight: 700;
  opacity: 1;
  background: linear-gradient(-45deg, #f89b29 0%, #F65909 100% );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1)
}

.card .content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 10px;
  background: linear-gradient(-45deg, #f89b29 10%, #F65909 100% );
  color: #e8e8e8;
  padding: 20px;
  line-height: 1.5;
  border-radius: 5px;
  pointer-events: none;
  transform: translateY(96%);
  transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
}

.card .content .heading {
  font-size: 1.4rem;
  font-weight: 700;
}

.card:hover .content {
  transform: translateY(0);
}

.card:hover .front-content {
  transform: translateY(-30%);
}

.card:hover .front-content p {
  opacity: 0;
}
.valores{
  margin-left: 2vw;
}
.tittleDark{
  color: #F65909;
  text-decoration: none;
  font-size: 25px;
  border: none;
  background: none;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
}

.tittleDark::before {
  margin-left: auto;
}

.tittleDark::after, .tittleDark::before {
  content: '';
  width: 0%;
  height: 2px;
  background: #F65909;
  display: block;
  transition: 0.5s;
}

.tittleDark:hover::after, .tittleDark:hover::before {
  width: 100%;
}
}
@media only screen and (max-width: 768px){

.sobre h1{
  color: rgb(34, 33, 33);
  padding: 0;
  border-radius: 10px;
  text-align: center;
  margin-top: 2vh;
}
.cardSobre{

  height: 55%;
  width: 100vw;
  flex-direction: column;
  margin-left: 5vw;
  margin-right: 5vw;
  text-align: center;
  align-items: center;
  font-family: "Inter", sans-serif;   

}
.cardSobre h4{
  width: 100vw;
  font-size: 0.9rem;
  text-align: center;
}
.card{
font-family: "Inter", sans-serif;   

}
.cardSobre h3{
  margin-top: 3vh;
  font-family: "Inter", sans-serif;   
}
.cardSobre img{
  width: 45vw;
  height: 30vh;
}
.sobre{
  overflow: hidden;
  align-items: center;
  display: flex;
  height: 155vh;
  flex-direction: column;
}
.containerSobre{
  display: flex;
  flex-direction: column;
}


.mvv{
  display: flex;
  width: 100vw;
  height: 100vh;
  flex-direction: row;
  justify-content: center;
}
.mCont{
  height: 35vh;
  margin-left: 10vw;

}
.vCont{
  margin-left: 2.3vw;
  height: 35vh;
  display: flex;
  flex-direction: row;
}

.card-container {
  width:30vw;
  height: 60vh;
  overflow-x: visible;
  position: relative;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.card {
  width: 100%;
  height: 100%;
  border-radius: inherit;
}

.card .front-content {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 10)
}

.card .front-content p {
  font-size: 1.2rem;
  font-weight: 700;
  opacity: 1;
  background: linear-gradient(-45deg, #f89b29 0%, #F65909 100% );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1)
}

.card .content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 10px;
  background: linear-gradient(-45deg, #f89b29 10%, #F65909 100% );
  color: #e8e8e8;
  padding: 20px;
  line-height: 1.5;
  border-radius: 5px;
  pointer-events: none;
  transform: translateY(96%);
  transition: all 0.6s cubic-bezier(0.23, 0, 0.320, 1);
}
.card .content h6{
  font-size: 0.6rem;
}
.card .content h5{
  font-size: 0.7rem;
}
.card .content .heading {
  font-size: 1.4rem;
  font-weight: 700;
}

.card:hover .content {
  transform: translateY(0);
}

.card:hover .front-content {
  transform: translateY(-50%);
}

.card:hover .front-content p {
  opacity: 0;
}
.valores{
  margin-left: 2vw;
}
.tittleDark{
  color: #F65909;
  text-decoration: none;
  font-size: 25px;
  border: none;
  background: none;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
}

.tittleDark::before {
  margin-left: auto;
}

.tittleDark::after, .tittleDark::before {
  content: '';
  width: 0%;
  height: 2px;
  background: #F65909;
  display: block;
  transition: 0.5s;
}

.tittleDark:hover::after, .tittleDark:hover::before {
  width: 100%;
}
}
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap')