@media only screen and (max-width: 768px) {


  .indicacoes{
      align-items: center;
      height: 90vh;
      width: 100vw;
      display: flex;
      flex-direction: column;
      background-color: #F65E06;
      background-image: radial-gradient(rgba(255, 255, 255, 0.171) 2px, transparent 0);
      background-size: 30px 30px;
      background-position: -5px -5px
  }
  .personagemInd img{
    height: 30vh;
  }
  .texInd h1{
      color: whitesmoke;
      cursor: pointer;
      padding: 0;
      width: 100vw;
      border-radius: 10px;
      text-align: center;
      margin-top: 2vh;
  }
  .containerIndicacoes{
      
      font-family: "Inter", sans-serif;  
      color: antiquewhite;
      width: 100vw;
      text-align: center;
      display: flex;
      flex-direction: column;
  }
  .textInd{
      width: 100vw;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
  }
  
  .textInd .animated-button {
      position: relative;
      display: flex;
      align-items: center;
      gap: 4px;
      padding: 16px 36px;
      border: 4px solid;
      border-color: transparent;
      font-size: 16px;
      background-color: inherit;
      border-radius: 100px;
      font-weight: 600;
      color: #fff;
      box-shadow: 0 0 0 2px #fff;
      cursor: pointer;
      overflow: hidden;
      transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
    }
    
    .textInd .animated-button svg {
      position: absolute;
      width: 24px;
      fill: #fff;
      z-index: 9;
      transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
    }
    
    .textInd .animated-button .arr-1 {
      right: 16px;
    }
    
    .textInd .animated-button .arr-2 {
      left: -25%;
    }
    
    .textInd .animated-button .circle {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 20px;
      height: 20px;
      background-color: #fff;
      border-radius: 50%;
      opacity: 0;
      transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
    }
    
    .textInd .animated-button .text {
      position: relative;
      z-index: 1;
      transform: translateX(-12px);
      transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
    }
    
    .textInd .animated-button:hover {
      box-shadow: 0 0 0 12px transparent;
      color: #000;
      border-radius: 12px;
    }
    
    .textInd .animated-button:hover .arr-1 {
      right: -25%;
    }
    
    .textInd .animated-button:hover .arr-2 {
      left: 16px;
    }
    
    .textInd .animated-button:hover .text {
      transform: translateX(12px);
    }
    
    .textInd .animated-button:hover svg {
      fill: #000;
    }
    
    .textInd .animated-button:active {
      scale: 0.95;
      box-shadow: 0 0 0 4px greenyellow;
    }
    
    .textInd .animated-button:hover .circle {
      width: 220px;
      height: 220px;
      opacity: 1;
    }
    .tittleWithe{
      color: #f3f3f3;
      text-decoration: none;
      font-size: 25px;
      border: none;
      background: none;
      font-weight: 600;
      font-family: 'Poppins', sans-serif;
    }
    
    .tittleWithe::before {
      margin-left: auto;
    }
    
    .tittleWithe::after, .tittleWithe::before {
      content: '';
      width: 0%;
      height: 2px;
      background: #f3f3f3;
      display: block;
      transition: 0.5s;
    }
    
    .tittleWithe:hover::after, .tittleWithe:hover::before {
      width: 100%;
    }
    
  }


@media only screen and (min-width: 769px) {


.indicacoes{
    align-items: center;
    height: 90vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    background-color: #F65E06;
    background-image: radial-gradient(rgba(255, 255, 255, 0.171) 2px, transparent 0);
    background-size: 30px 30px;
    background-position: -5px -5px
}
.personagemInd img{
  height: 70vh;
}
.texInd h1{
    color: whitesmoke;
    ;
    cursor: pointer;
    padding: 0;
    width: 14vw;
    border-radius: 10px;
    text-align: center;
    margin-top: 2vh;
}
.containerIndicacoes{
    
    font-family: "Inter", sans-serif;  
    color: antiquewhite;
    width: 100vw;
    text-align: center;
    display: flex;
    flex-direction: row;
}
.textInd{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.textInd .animated-button {
    position: relative;
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 16px 36px;
    border: 4px solid;
    border-color: transparent;
    font-size: 16px;
    background-color: inherit;
    border-radius: 100px;
    font-weight: 600;
    color: #fff;
    box-shadow: 0 0 0 2px #fff;
    cursor: pointer;
    overflow: hidden;
    transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
  }
  
  .textInd .animated-button svg {
    position: absolute;
    width: 24px;
    fill: #fff;
    z-index: 9;
    transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
  }
  
  .textInd .animated-button .arr-1 {
    right: 16px;
  }
  
  .textInd .animated-button .arr-2 {
    left: -25%;
  }
  
  .textInd .animated-button .circle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 20px;
    background-color: #fff;
    border-radius: 50%;
    opacity: 0;
    transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
  }
  
  .textInd .animated-button .text {
    position: relative;
    z-index: 1;
    transform: translateX(-12px);
    transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
  }
  
  .textInd .animated-button:hover {
    box-shadow: 0 0 0 12px transparent;
    color: #000;
    border-radius: 12px;
  }
  
  .textInd .animated-button:hover .arr-1 {
    right: -25%;
  }
  
  .textInd .animated-button:hover .arr-2 {
    left: 16px;
  }
  
  .textInd .animated-button:hover .text {
    transform: translateX(12px);
  }
  
  .textInd .animated-button:hover svg {
    fill: #000;
  }
  
  .textInd .animated-button:active {
    scale: 0.95;
    box-shadow: 0 0 0 4px greenyellow;
  }
  
  .textInd .animated-button:hover .circle {
    width: 220px;
    height: 220px;
    opacity: 1;
  }
  .tittleWithe{
    color: #f3f3f3;
    text-decoration: none;
    font-size: 25px;
    border: none;
    background: none;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
  }
  
  .tittleWithe::before {
    margin-left: auto;
  }
  
  .tittleWithe::after, .tittleWithe::before {
    content: '';
    width: 0%;
    height: 2px;
    background: #f3f3f3;
    display: block;
    transition: 0.5s;
  }
  
  .tittleWithe:hover::after, .tittleWithe:hover::before {
    width: 100%;
  }
  
}
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap')