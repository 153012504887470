.botao-scroll {
    position: fixed;
    bottom: 20px;
    right: 20px;
    opacity: 0;
    border: none;
    border-radius: 20px;
    transition: opacity 0.3s ease;
    cursor: pointer;
    z-index: 603000000000000000003;
  }
  
  .botao-scroll.mostrar {
    opacity: 1;
  }

  .botao-scroll.mostrar img{
    object-fit: fill;
height: 5vh;
width: 5vw;
padding: 0.5vh 0 0.5vh 0;
}