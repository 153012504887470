@media only screen and (max-width: 768px) {


  .home{
      background-image:linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(../../assets/imgs/background/férias-em-alagoas.png);
      font-family: "Inter", sans-serif;   
      color: whitesmoke;
      height: 70vh;
      width: 100vw;
      text-align: center;
      background-repeat: no-repeat;
      background-size: 100% 100%;
  }
  .home .container{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: 85%;
  }
  .homeText{
      align-items: center;
      height: 50%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
  }
  .textInd h2{
    margin-bottom: 4vh;
  }
  .animated-button {
      position: relative;
      display: flex;
      align-items: center;
      gap: 4px;
      padding: 16px 36px;
      border: 4px solid;
      border-color: transparent;
      font-size: 16px;
      background-color: inherit;
      border-radius: 100px;
      font-weight: 600;
      color: #F65909;
      box-shadow: 0 0 0 2px #F65909;
      cursor: pointer;
      overflow: hidden;
      transition: all 0.1s cubic-bezier(0.23, 1, 0.32, 1);
    }
    
    .animated-button svg {
      position: absolute;
      width: 24px;
      fill: #F65909;
      z-index: 9;
      transition: all 0.2s cubic-bezier(0.23, 1, 0.32, 1);
    }
    
    .animated-button .arr-1 {
      right: 16px;
    }
    
    .animated-button .arr-2 {
      left: -25%;
    }
    
    .animated-button .circle {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 20px;
      height: 20px;
      background-color: #F65909;
      border-radius: 50%;
      opacity: 0;
      transition: all 0.2s cubic-bezier(0.23, 1, 0.32, 1);
    }
    
    .animated-button .text {
      position: relative;
      z-index: 1;
      transform: translateX(-12px);
      transition: all 0.2s cubic-bezier(0.23, 1, 0.32, 1);
    }
    
    .animated-button:hover {
      box-shadow: 0 0 0 12px transparent;
      color: #212121;
      border-radius: 12px;
    }
    
    .animated-button:hover .arr-1 {
      right: -25%;
    }
    
    .animated-button:hover .arr-2 {
      left: 16px;
    }
    
    .animated-button:hover .text {
      transform: translateX(12px);
    }
    
    .animated-button:hover svg {
      fill: #212121;
    }
    
    .animated-button:active {
      scale: 0.95;
      box-shadow: 0 0 0 4px #F65909;
    }
    
    .animated-button:hover .circle {
      width: 220px;
      height: 220px;
      opacity: 1;
    }
    a{
      text-decoration: none;
    }
  }



@media only screen and (min-width: 769px) {


.home{
    background-image:linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(../../assets/imgs/background/férias-em-alagoas.png);
    font-family: "Inter", sans-serif;   
    color: whitesmoke;
    height: 70vh;
    width: 100vw;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.home .container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 85%;
}
.homeText{
    align-items: center;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.textInd h2{
  margin-bottom: 4vh;
}
.animated-button {
    position: relative;
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 16px 36px;
    border: 4px solid;
    border-color: transparent;
    font-size: 16px;
    background-color: inherit;
    border-radius: 100px;
    font-weight: 600;
    color: #F65909;
    box-shadow: 0 0 0 2px #F65909;
    cursor: pointer;
    overflow: hidden;
    transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
  }
  
  .animated-button svg {
    position: absolute;
    width: 24px;
    fill: #F65909;
    z-index: 9;
    transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
  }
  
  .animated-button .arr-1 {
    right: 16px;
  }
  
  .animated-button .arr-2 {
    left: -25%;
  }
  
  .animated-button .circle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 20px;
    background-color: #F65909;
    border-radius: 50%;
    opacity: 0;
    transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
  }
  
  .animated-button .text {
    position: relative;
    z-index: 1;
    transform: translateX(-12px);
    transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
  }
  
  .animated-button:hover {
    box-shadow: 0 0 0 12px transparent;
    color: #212121;
    border-radius: 12px;
  }
  
  .animated-button:hover .arr-1 {
    right: -25%;
  }
  
  .animated-button:hover .arr-2 {
    left: 16px;
  }
  
  .animated-button:hover .text {
    transform: translateX(12px);
  }
  
  .animated-button:hover svg {
    fill: #212121;
  }
  
  .animated-button:active {
    scale: 0.95;
    box-shadow: 0 0 0 4px #F65909;
  }
  
  .animated-button:hover .circle {
    width: 220px;
    height: 220px;
    opacity: 1;
  }
  a{
    text-decoration: none;
  }
}
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap')