
*{
    margin:0;
    padding: 0;
    box-sizing: border-box;
}



#bt1 {
    margin: 0;
    height: auto;
    background: transparent;
    padding: 0;
    border: none;
    cursor: pointer;
  }
  #bt1 {
    --border-right: 2px;
    --text-stroke-color: rgba(255,255,255,0.6);
    --animation-color: #fd8700;
    --fs-size: 0.8em;
    letter-spacing: 3px;
    text-decoration: none;
    font-size: var(--fs-size);
    font-family: "Arial";
    position: relative;
    text-transform: uppercase;
    color: transparent;
    -webkit-text-stroke: 1px var(--text-stroke-color);
  }
  #bt1hover-text {
    position: absolute;
    box-sizing: border-box;
    content: attr(data-text);
    color: var(--animation-color);
    width: 0%;
    inset: 0;
    border-right: var(--border-right) solid var(--animation-color);
    overflow: hidden;
    transition: 0.5s;
    -webkit-text-stroke: 1px var(--animation-color);
  }
  #bt1:hover #bt1hover-text {
    width: 100%;
    filter: drop-shadow(0 0 23px var(--animation-color))
  }





@media only screen and (min-width: 769px) {
.bt{
    display: none;
}
.support{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: Arial, Helvetica, sans-serif;
    color: rgba(245, 245, 245, 0.884);
}
.support button{
    margin-left: 0.5vw;
}
.navbar{
    background: rgb(161, 75, 29);
    background: linear-gradient(180deg, rgb(141, 68, 28) 0%, rgba(251,94,7,0.011642156862745057) 100%);justify-content: space-between;
    text-align: center;
    justify-content: space-between;
    animation: size 2s linear;
    width: 100vw;
    display: flex;
    height: 10vh;
    padding: 0 2vw;
}
.modal-off{
    display: none;
}
.navbar button img{
    height: 3vh;
}
.navbar button{
    background-color: transparent;
    border: none;
    cursor: pointer;
}
@keyframes size {
    from{ 
        height: 0;
    }
    to{
        height: 10;
    }
}
.logo{
    border-radius: 100%;
    width: auto;
}
.navbar h3{
margin-top: 3vh;
font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
color: whitesmoke;
}
.links{
    width: 45%;
    align-items: center;
    display: flex;
    justify-content: space-around;
}
.links a{ 
    color: whitesmoke;
    text-decoration: none;
}
#contatenos:hover{
    color: #F65909;
}
#serviços:hover{
    color: #F65909;
}#feedbacks:hover{
    color: #F65909;
}#conheçanos:hover{
    color: #F65909;
}}
@media only screen and (max-width: 768px){
.support{
    display: none;
}
.navbar{
    background: rgb(251,94,7);
    background: linear-gradient(180deg, rgba(251,94,7,1) 0%, rgba(251,94,7,0.011642156862745057) 100%);justify-content: space-between;
    text-align: center;
    justify-content: space-between;
    animation: size 2s linear;
    width: 100vw;
    display: flex;
    height: 10vh;
    padding: 0 2vw;
}
.links{
    display: none;
}

.logo{
    border-radius: 100%;
    width: auto;
}
.modal-off{
    display: none;
}
@keyframes size {
    from{ 
        height: 0;
    }
    to{
        height: 10;
    }
}
}
.navbar button img{
    height: 3vh;
}
.navbar button{
    background-color: transparent;
    border: none;
    cursor: pointer;
}
.modal-on{
    z-index: 9930000000060000000000000000090000;
    position: absolute;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items:center ;
    flex-direction: row;
}
.contModal ul button{
    width: 80%;
    height: 16%;
    font-size: 1rem;
    background-color: transparent;
    border-top-left-radius: 50px;
    border-bottom-right-radius: 50px;
    border-left: none;
    border-right: none;
    border-color: #F65909;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
}
.contModal ul, .contModal a{
    list-style-type: none;

    color: whitesmoke;
    text-decoration: none;
    display: flex;
flex-direction: column;
align-items: center;
justify-content: space-around;
height: 80%;
}
.contbutton-off{
    display: none;
}
.topModal{
    margin-top: 2vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.topModal button{
    background-color: transparent;
    border: none;
}
.cross{
    height: 6vh;
}
.contModal h2{
    color: whitesmoke;
    margin-right: 20vw;
    margin-left: 28vw;
}
.contModal{
    background-color: rgb(14, 13, 13);
    margin-left: 10vw;
width: 80vw;
height: 100vh;
border: 1px solid black;
list-style: none;
text-decoration: none;
align-items: center;
justify-content: center;
}
